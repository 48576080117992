.BlocSearchBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  .SearchBar {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: var(--creamDarker);
    padding: 0rem 2rem;
    border-radius: 3rem;
    width: 40%;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    z-index: 20;

    input {
      padding-top: 1.6rem;
      padding-bottom: 1.2rem;
    }

    &:hover {
      background: var(--creamDarkest);

      input {
        background: var(--creamDarkest);
      }
    }

    svg {
      font-size: 2.4rem;
      color: var(--chocolate);
      opacity: 0.25;
    }

    &:focus-within {
      background: var(--white);

      input {
        background: var(--white);
      }
    }
  }

  .SearchBarResult {
    display: none;
    position: absolute;
    width: 40%;
    background-color: var(--white);
    top: 5rem;
    padding-bottom: 1rem;
    padding-top: 5rem;
    border-radius: 0rem 0rem 1rem 1rem;
    max-height: 50rem;
    overflow-x: hidden;
    overflow-y: scroll;

    ul {
      width: 100%;
      list-style: none;
      padding-left: 0rem;

      li {
        border-bottom: 0.1rem solid var(--cream);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
      }

      li:last-child {
        border-bottom: 0rem solid var(--cream);
      }
    }

    &.visible {
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .BlocSearchBar {
    .SearchBar {
      width: 70%;
    }

    .SearchBarResult {
      width: 70%;
      max-height: 40rem;
    }
  }
}

@media screen and (max-width: 834px) {
  .BlocSearchBar {
    .SearchBar {
      width: 80%;
    }

    .SearchBarResult {
      width: 80%;
    }
  }
}

@media screen and (max-width: 568px) {
  .BlocSearchBar {
    .SearchBar {
      width: calc(
        100vw - var(--paddingAppSizeResponsive) -
          var(--paddingAppSizeResponsive)
      );
    }

    .SearchBarResult {
      width: calc(
        100vw - var(--paddingAppSizeResponsive) -
          var(--paddingAppSizeResponsive)
      );
      max-height: 40rem;
    }
  }
}
