@mixin letter_iil {
  position: absolute;
  background-color: var(--white);
  width: 1rem;
  border-radius: 1rem;
}

@mixin circle_iil {
  width: 14rem;
  height: 14rem;
  background-color: var(--gold);
  border-radius: 50%;
  top: 10px;
}

.LoadingIIL {
  position: relative;

  .circle {
    @include circle_iil();

    .iil-box {
      position: absolute;
      top: 3.5rem;
      left: 4.5rem;
      height: 7rem;
      width: 5.5rem;

      .small-i {
        .point {
          @include letter_iil();
          height: 1rem;
          top: 1.2rem;
          left: 0rem;
          animation: bounce-small-i 0.5s ease-in-out infinite both,
            jello-horizontal 0.5s ease-in-out infinite both;
        }

        .line {
          @include letter_iil();
          height: 3.8rem;
          top: 3.2rem;
          left: 0rem;
        }
      }

      .big-i {
        .point {
          @include letter_iil();
          height: 1rem;
          top: 0rem;
          left: 2.25rem;
          animation: bounce-big-i 0.5s ease-in-out infinite both,
            jello-horizontal 0.5s ease-in-out infinite both;
          animation-delay: 0.2s;
        }

        .line {
          @include letter_iil();
          height: 5rem;
          top: 2rem;
          left: 2.25rem;
        }
      }

      .big-l {
        .line {
          @include letter_iil();
          height: 7rem;
          top: 0rem;
          left: 4.5rem;
        }
      }
    }
  }
}
