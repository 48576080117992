.CatalogActivities {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    list-style: none;
    padding-left: 0rem;
  }
}

@media screen and (max-width: 568px) {
  .CatalogActivities {
    ul {
      grid-template-columns: 1fr;
      gap: 1.6rem;
    }
  }
}
