@mixin inputStyle {
  appearance: none;
  width: 100%;
  outline: 0rem;
  border: 0rem;
  padding: 1.25rem;
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  background: var(--creamDarker);
  color: var(--chocolate);
  border-radius: 0.3rem;

  &::placeholder {
    color: var(--chocolate);
    opacity: 0.25;
  }

  &:hover {
    background: var(--creamDarkest);
  }

  &:focus {
    background: var(--white);
  }
}
