/* Helvetica Neue Family */
@font-face {
  font-family: "HelveticaNeueLTStd-Medium";
  src: local("HelveticaNeueLTStd-Medium");
  src: url("./../../fonts/HelveticaNeueLTStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Bold";
  src: local("HelveticaNeueLTStd-Bold");
  src: url("./../../fonts/HelveticaNeueLTStd-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Varela Round Family */
@font-face {
  font-family: "VarelaRound-Regular";
  src: local("VarelaRound-Regular");
  src: url("./../../fonts/VarelaRound-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
