@import "../mixins/buttonStyle";
@import "../mixins/inputStyle";

/** BASE */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  line-height: 1.25;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  position: relative;
  background: var(--cream);
  color: var(--chocolate);
  font-family: "HelveticaNeueLTStd-Medium", Helvetica, Arial, sans-serif;
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
  font-size: 1.6rem;
}

main {
  display: block;
  z-index: 0;
}

/** TITLE */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: var(--medium);
  margin-bottom: 1rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

/** PARAGRAPH */
p {
  margin-bottom: 1rem;
  text-indent: 0rem;
}

/** DEFINITION LIST */
dl {
  margin-bottom: 1rem;

  dt {
    padding-bottom: 0.5rem;
    color: var(--pharlap);
    font-size: 1.4rem;
  }

  dd {
    margin-bottom: 1rem;

    *:last-child {
      padding-bottom: 0rem;
    }
  }
}

/** LIST */
ul,
ol {
  padding-left: 2rem;
}

/** BLOCKQUOTE */
blockquote {
  margin-bottom: 1rem;
}

/** DETAILS **/
details {
  margin-bottom: 1rem;

  summary {
    color: var(--pharlap);
    border-bottom: 1px solid var(--creamDarkest);
    margin-bottom: 0.5rem;

    &:hover {
      color: var(--chocolate);
      cursor: pointer;
    }
  }
}

/** ADDRESS */
address {
  font-style: normal;
  margin-bottom: 1rem;
}

/** HR */
hr {
  border: 0px solid;
  border-top: 2px;
  border-color: var(--cold-turkey);
  border-style: solid;
}

/** TABLE */
table {
  width: 100%;
  border-collapse: collapse;

  caption {
    color: var(--pharlap);
    font-style: normal;
  }

  thead,
  tfoot {
    background-color: var(--creamDarkest);

    th {
      font-weight: var(--medium);
      text-align: left;
      padding: 1rem;
      padding-top: 1.4rem;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: var(--creamDarker);
      }

      &:nth-child(even) {
        background-color: var(--cream);
      }

      td {
        padding: 1rem;
        padding-top: 1.4rem;
      }
    }
  }
}

/** CODE */
kbd,
code,
samp {
  background-color: var(--creamDarker);
  padding: 0.4rem 1rem;
  color: var(--chocolate);
}

pre {
  background-color: var(--creamDarker);
  padding: 0.4rem 1rem;
  color: var(--chocolate);
  margin-bottom: 1rem;
}

/** INLINE ELEMENT */
strong,
b {
  font-weight: var(--medium);
}

em,
i {
  font-style: normal;
}

u {
  text-decoration: none;
}

del {
  background-color: var(--backgroundDanger);
  padding: 0.2rem 0.4rem;
  padding-top: 0.4rem;
}

ins {
  background-color: var(--backgroundSuccess);
  padding: 0.2rem 0.4rem;
  padding-top: 0.4rem;
}

s {
  text-decoration: line-through;
}

sub,
sup {
  font-size: 1.2rem;
}

small {
  font-size: 1.2rem;
}

abbr {
  color: var(--pharlap);
}

cite {
  font-style: normal;
  color: var(--pharlap);
}

mark {
  background-color: var(--pharlap);
  color: var(--cream);
  padding: 0.2rem 0.4rem;
  padding-top: 0.4rem;
}

var {
  font-style: italic;
  color: var(--pharlap);
}

time {
  font-family: monospace;
}

/** LINK */
a {
  text-decoration: none;
  color: var(--parsley);

  &:hover {
    color: var(--celtic);
  }

  &:visited {
    color: var(--parsley);
  }

  &.button-link {
    @include buttonStyle;

    &.button-white {
      @include buttonWhite;
    }

    &.button-secondary {
      @include buttonSecondary;
    }

    &.button-danger {
      @include buttonDanger;
    }

    &.button-primary {
      @include buttonPrimary;
    }

    &.button-info {
      @include buttonInfo;
    }

    &.button-warning {
      @include buttonWarning;
    }

    &.button-success {
      @include buttonSuccess;
    }
  }
}

/** EMBEDED */
img {
  max-width: 100%;
  height: auto;
}

figure {
  margin-bottom: 1rem;

  figcaption {
    color: var(--pharlap);
  }
}

audio,
video,
canvas,
object,
iframe,
embed {
  width: 100%;
  appearance: none;
  margin-bottom: 1rem;
}

meter {
  appearance: none;
}

progress {
  appearance: none;
}

/** FORM ELEMENTS */
fieldset {
  border-width: 0.2rem;
  border-color: var(--creamDarkest);
  border-style: solid;
  max-width: 100%;

  legend {
    padding-left: 0.2rem;
    padding-right: 0.6rem;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  text-transform: none;
  font-weight: inherit;

  &:disabled {
    cursor: not-allowed;
  }
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="date"],
input[type="datetime"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
textarea {
  @include inputStyle;
}

/** BUTTONS */
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  @include buttonStyle;

  &.button-primary {
    @include buttonPrimary;
  }

  &.button-secondary {
    @include buttonSecondary;
  }

  &.button-white {
    @include buttonWhite;
  }

  &.button-danger {
    @include buttonDanger;
  }

  &.button-warning {
    @include buttonWarning;
  }

  &.button-info {
    @include buttonInfo;
  }

  &.button-success {
    @include buttonSuccess;
  }
}
