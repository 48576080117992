.text-danger {
  color: var(--danger);
}

.text-warning {
  color: var(--warning);
}

.text-info {
  color: var(--info);
}

.text-success {
  color: var(--success);
}
