@import "../mixins/gradientLoading";

.LoadingCategories {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  gap: 3.2rem;

  div {
    width: 5%;
    height: 2rem;
    @include gradientLoading;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 414px) {
  .LoadingCategories {
    gap: 1rem;

    div {
      width: 10%;
    }
  }
}
