@mixin buttonStyle {
  appearance: none;
  background: none;
  cursor: pointer;
  padding: 1rem 2.8rem;
  padding-top: 1.4rem;
  border: 0.2rem solid transparent;
  border-radius: 3rem;
  font-size: 1.6rem;

  svg {
    margin-right: 1rem;
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
}

@mixin buttonPrimary {
  background-color: var(--chocolate);
  color: var(--cream);
  border-color: var(--chocolate);

  &:hover {
    background-color: var(--chocolateDarker);
    border-color: var(--chocolateDarker);
  }

  &.button-outline {
    border-color: var(--chocolate);
    color: var(--chocolate);
    background-color: transparent;

    &:hover {
      background-color: var(--chocolateDarker);
      color: var(--cream);
      border-color: var(--chocolateDarker);
    }
  }
}

@mixin buttonSecondary {
  background-color: var(--pharlap);
  color: var(--cream);
  border-color: var(--pharlap);

  &:hover {
    background-color: var(--pharlapDarker);
    border-color: var(--pharlapDarker);
  }

  &.button-outline {
    border-color: var(--pharlap);
    color: var(--pharlap);
    background-color: transparent;

    &:hover {
      background-color: var(--pharlapDarker);
      color: var(--cream);
      border-color: var(--pharlapDarker);
    }
  }
}

@mixin buttonDanger {
  background-color: var(--danger);
  color: var(--cream);
  border-color: var(--danger);

  &:hover {
    background-color: var(--dangerDarker);
    border-color: var(--dangerDarker);
  }

  &.button-outline {
    border-color: var(--danger);
    color: var(--danger);
    background-color: transparent;

    &:hover {
      background-color: var(--dangerDarker);
      color: var(--cream);
      border-color: var(--dangerDarker);
    }
  }
}

@mixin buttonSuccess {
  background-color: var(--success);
  color: var(--cream);
  border-color: var(--success);

  &:hover {
    background-color: var(--successDarker);
    border-color: var(--successDarker);
  }

  &.button-outline {
    border-color: var(--success);
    color: var(--success);
    background-color: transparent;

    &:hover {
      background-color: var(--successDarker);
      color: var(--cream);
      border-color: var(--successDarker);
    }
  }
}

@mixin buttonWarning {
  background-color: var(--warning);
  color: var(--cream);
  border-color: var(--warning);

  &:hover {
    background-color: var(--warningDarker);
    border-color: var(--warningDarker);
  }

  &.button-outline {
    border-color: var(--warning);
    color: var(--warning);
    background-color: transparent;

    &:hover {
      background-color: var(--warningDarker);
      color: var(--cream);
      border-color: var(--warningDarker);
    }
  }
}

@mixin buttonInfo {
  background-color: var(--info);
  color: var(--cream);
  border-color: var(--info);

  &:hover {
    background-color: var(--infoDarker);
    border-color: var(--infoDarker);
  }

  &.button-outline {
    border-color: var(--info);
    color: var(--info);
    background-color: transparent;

    &:hover {
      background-color: var(--infoDarker);
      color: var(--cream);
      border-color: var(--infoDarker);
    }
  }
}

@mixin buttonWhite {
  background-color: var(--white);
  color: var(--chocolate);
  border-color: var(--white);

  &:hover {
    background-color: var(--whiteDarker);
    border-color: var(--whiteDarker);
    color: var(--chocolate);
  }

  &.button-outline {
    border-color: var(--white);
    color: var(--white);
    background-color: transparent;

    &:hover {
      background-color: var(--whiteDarker);
      color: var(--cream);
      border-color: var(--whiteDarker);
    }
  }
}
