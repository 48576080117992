@import "../mixins/pastilleActivity";

.CardActivity,
a.CardActivity {
  position: relative;
  color: var(--white);

  &.disappear {
    -webkit-animation: puff-out-hor 0.9s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: puff-out-hor 0.9s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }

  &:hover {
    color: var(--white);
  }

  &.complete {
    article {
      .shadow {
        background: var(--shadowFull);
      }
    }
  }

  article {
    position: inherit;
    width: 100%;
    height: 25rem;
    background-color: var(--chocolate);
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    animation: backgroundZoomOut var(--animationDuration) forwards;

    .type-activite {
      /* Common */
      position: absolute;
      background-color: transparent;

      /* Position boule */
      @include boule;

      /* Position corner */
      // @include corner;

      /* Position barre à gauche */
      // @include barre;

      &.blue {
        background-color: var(--cerulean);
      }

      &.orange {
        background-color: var(--orange);
      }
    }

    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--shadowBase);
      z-index: 0;
      transition: background var(--animationDuration) ease-in-out;
    }

    .content {
      position: inherit;
      z-index: 1;
      padding: 2rem 2.5rem 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      .information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;

        h2 {
          padding-bottom: 0.6rem;
        }

        .meta-information {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1.5fr;

          .schedule {
            display: grid;
            grid-template-columns: 1fr auto;

            .price {
              display: flex;
              font-weight: 700;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-start;
              border-right: 0.2rem solid rgba(255, 255, 255, 0.5);
              padding-right: 2rem;
              font-family: monospace;
            }

            .timetable {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              padding-left: 2rem;

              em {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  &:hover article {
    animation: backgroundZoomIn var(--animationDuration) forwards;

    .shadow {
      background: var(--shadowHover);
    }
  }
}

@media screen and (max-width: 834px) {
  .CardActivity,
  a.CardActivity {
    article {
      height: 20rem;

      .content {
        .information {
          .meta-information {
            .schedule {
              grid-template-columns: 1fr;

              .price {
                border-right: 0rem;
                font-size: 1.8rem;
                font-family: monospace;
                padding-right: 0rem;
                padding-bottom: 0.6rem;
              }
              .timetable {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
