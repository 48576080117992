.badge {
  background-color: var(--cream);
  display: inline;
  padding: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.3rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "HelveticaNeueLTStd-Bold";
  border-radius: 0.5rem;
  color: var(--white);

  &.orange {
    background-color: var(--orange);
  }

  &.blue {
    background-color: var(--cerulean);
  }
}
