@keyframes backgroundZoomIn {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 107%;
  }
}

@keyframes backgroundZoomOut {
  0% {
    background-size: 107%;
  }
  100% {
    background-size: 100%;
  }
}
