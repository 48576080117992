@import "../mixins/gradientLoading";

.LoadingSearchBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 2rem;

  div {
    width: 40%;
    height: 6rem;
    @include gradientLoading;
    border-radius: 3rem;
  }
}

@media screen and (max-width: 414px) {
  .LoadingSearchBar {
    div {
      width: 90%;
    }
  }
}
