.MenuCategories {
  display: grid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ol {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3.2rem;
    padding-left: 0rem;
    list-style: none;
  }
}

@media screen and (max-width: 568px) {
  .MenuCategories {
    justify-content: flex-start;
    width: calc(100vw - var(--paddingAppSizeResponsive));
    padding-left: var(--paddingAppSizeResponsive);
    overflow-x: scroll;
    overflow-y: hidden;
    height: 5rem;
    margin-top: 1.5rem;
    padding-right: 3.2rem;
    z-index: 0;

    &::after {
      position: absolute;
      display: block;
      content: "";
      right: 0px;
      width: 3.4rem;
      height: 2.4rem;
      margin-top: -0.6rem;
      background: linear-gradient(to right, transparent 0%, var(--cream));
      z-index: 1;
    }

    /* Retrait de la scroll bar du navigateur */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
