@import "../mixins/gradientLoading";

.LoadingCart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  div:nth-child(1),
  div:nth-child(2),
  div:nth-child(3) {
    background-color: var(--chocolate);
    height: 10rem;
    margin-bottom: 1rem;
    width: 100%;
    @include gradientLoading;
  }

  div:nth-child(4) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    div {
      background-color: var(--chocolate);
      height: 5rem;
      margin-bottom: 1rem;
      width: 20%;
      @include gradientLoading;
    }
  }
}
