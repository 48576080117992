@import "../mixins/gradientLoading";

.LoadingRegisterButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  div {
    height: 5rem;
    width: 20rem;
    @include gradientLoading;
  }
}
