.ChildOrders {
  margin-bottom: 3.2rem;
}

@media screen and (max-width: 1024px) {
  .ChildOrders {
    max-width: calc(100vw - var(--paddingAppSize) - var(--paddingAppSize));
    overflow: scroll;

    .table-area {
      max-width: 100%;
      overflow: scroll;

      table {
        min-width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ChildOrders {
    max-width: calc(
      100vw - var(--paddingAppSizeResponsive) - var(--paddingAppSizeResponsive)
    );
    overflow: scroll;

    .table-area {
      max-width: 100%;
      overflow: scroll;

      table {
        min-width: 1000px;
      }
    }
  }
}
