.Loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0rem;
  left: 0rem;
  background-color: var(--cream);
  gap: 2rem;
}
