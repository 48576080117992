.Header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding-left: var(--paddingAppSize);
  padding-right: var(--paddingAppSize);
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  font-size: 1.8rem;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .Header {
    display: none;
  }
}
