.Timetable {
  margin-top: 2.4rem;
  overflow: scroll;
  position: relative;
  height: 100rem;

  table {
    border: 0.1rem solid var(--creamDarkest);
    position: relative;
    height: 100px;

    thead {
      th {
        text-align: center;
      }

      th.stickyHeader {
        position: sticky;
        top: 0px;
        background-color: var(--creamDarkest);
        z-index: 2;
      }
    }

    tbody {
      tr {
        td.stickyLine {
          position: sticky;
          left: 0px;
          background-color: var(--creamDarkest);
          border: 0.1rem solid var(--creamDarker);
          z-index: 1;
        }

        td {
          height: 10rem;
          min-height: 10rem;
          border-right: 0.1rem solid var(--creamDarkest);

          .edt {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }

          .period {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .Timetable {
    height: 60rem;
  }
}
