.CountDownTimer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .TimerElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 4.5rem;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--contessa);
      background-color: var(--white);
      width: 3rem;
      height: 3rem;
      font-weight: 700;
      border-radius: 0.3rem;
      font-family: monospace;
    }

    small {
      text-transform: uppercase;
      font-size: 0.8rem;
      letter-spacing: 0.2rem;
    }
  }
}
