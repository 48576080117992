:root {
  /* Colors IIL */
  --gold: #836841;
  --parsley: #2b5234;
  --spanish-green: #859880;
  --celtic: #1b2f1c;
  -palm: #0a170b;
  --chocolate: #443630;
  --pharlap: #918278;
  --cold-turkey: #d0c3bc;
  --cream: #e9e9e3;
  --contessa: #bc7461;

  /* Accent colors */
  --blood: #cf2700;
  --white: #ffffff;
  --danger: #b53113;
  --warning: #c1951a;
  --success: #356631;
  --info: #1474b9;
  --orange: #ffb700;
  --cerulean: #336aff;

  /* Variation colors */
  --creamDarker: #e0e0d9;
  --creamDarkest: #d6d6cf;
  --dangerDarker: #94270e;
  --dangerDarkest: #4a1306;
  --chocolateDarker: #322925;
  --pharlapDarker: #796d64;
  --whiteDarker: #f0efef;
  --successDarker: #2b5227;
  --warningDarker: #a78016;
  --infoDarker: #1164a0;

  /* Alert Colors */
  --backgroundNone: rgba(156, 141, 131, 0.15);
  --backgroundDanger: #ead5d1;
  --backgroundInfo: #d6ddf0;
  --backgroundWarning: #ece4ce;
  --backgroundSuccess: #d0dfd4;

  /* Shadows */
  --shadowBase: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.05));
  --shadowHover: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.08));
  --shadowPlain: rgba(0, 0, 0, 0.3);
  --shadowFull: rgba(0, 0, 0, 0.8);
  --shadowLoading: rgba(233, 233, 227, 0.9);

  /* Gradient loading */
  --gradientLoading: linear-gradient(
    -45deg,
    var(--creamDarker),
    rgba(68, 54, 48, 0.5),
    rgba(145, 130, 120, 0.5)
  );

  /* Duration */
  --animationDuration: 300ms;
  --animationDurationShort: 150ms;

  /* App size */
  --maxAppWidth: 136rem;
  --paddingAppSize: 4rem;
  --paddingAppSizeResponsive: 1rem;

  /* Font weight */
  --medium: 500;
}
