.Pastille {
  position: absolute;
  background-color: var(--blood);
  color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-family: "HelveticaNeueLTStd-Bold";
  padding-top: 0.2rem;
  border-radius: 50%;
  top: -1.2rem;
  left: -1.2rem;
}
