@import "../mixins/_buttonStyle.scss";

.swal2-title {
  font-size: 3.2rem !important;
  font-weight: 500 !important;
}

.swal2-html-container {
  font-size: 1.6rem !important;
}

.swal2-actions {
  gap: 1rem !important;
}

.swal2-popup {
  padding-bottom: 3rem !important;
}

.swal2-icon .swal2-icon-content {
  padding-top: 1rem !important;
}

@media screen and (max-width: 768px) {
  .swal2-title {
    font-size: 2.4rem !important;
  }

  .swal2-popup {
    width: 90% !important;
  }
}
