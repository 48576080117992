@import "../mixins/gradientLoading";

.LoadingCartLineInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  div {
    background-color: var(--chocolate);
    height: 3rem;
    width: 100%;
    @include gradientLoading;
  }
}
