.ProgressBar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  &.complete {
    .background {
      .foreground {
        background-color: var(--blood);
      }
    }
  }

  .background {
    height: 0.8rem;
    width: 100%;
    background-color: var(--white);
    border-radius: 1rem;
    border: 0rem solid var(--white);

    .foreground {
      height: 100%;
      background-color: var(--contessa);
      border: 0rem;
      border-radius: 1rem;
      animation: progressBarAnimation var(--animationDuration);
    }

    &.hidden {
      display: none;
    }
  }
}
