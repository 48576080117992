@import "../mixins/gradientLoading";

.LoadingOpeningDates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 1rem;

  div {
    background-color: var(--chocolate);
    height: 2rem;
    width: 50%;
    @include gradientLoading;
  }
}
