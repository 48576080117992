.Filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;

  .filtrage-categories {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .button-filter button {
    padding-left: 0rem;
    padding-right: 0rem;
    color: var(--chocolate);

    span {
      position: relative;

      .Pastille {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }

  .reset {
    color: var(--contessa);
    padding-left: 0rem;
  }

  .filter-zone {
    background-color: var(--creamDarkest);
    width: 100%;
    padding: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 0rem;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    .filters-group {
      display: flex;
      flex-direction: row;
      gap: 8rem;
    }

    &.visible {
      display: flex;
    }

    fieldset {
      legend {
        text-transform: uppercase;
        font-size: 1.4rem;
      }

      ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-left: 0rem;
        padding-top: 1rem;
        gap: 2rem;
        flex-wrap: wrap;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 568px) {
  .Filters {
    .filter-zone {
      padding-left: 1rem;
      padding-right: 1rem;
      gap: 6rem;

      .filters-group {
        display: flex;
        flex-direction: column;
        gap: 6rem;
      }
    }
  }
}
