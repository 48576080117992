@import "../mixins/gradientLoading";

.LoadingActivity {
  width: 100%;
  display: grid;
  grid-template-columns: 40rem 1fr;
  gap: 4rem;
  margin-top: 4rem;

  .part {
    @include gradientLoading;
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
  }

  .photo {
    width: 100%;
    @include gradientLoading;
    height: 40rem;
  }

  .content {
    .title {
      @include gradientLoading;
      width: 50%;
      height: 6rem;
      margin-bottom: 4rem;
    }

    .bloc {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 4rem;
      margin-bottom: 4rem;

      .button {
        @include gradientLoading;
        width: 100%;
        height: 6rem;
        margin-bottom: 1rem;
      }
    }

    .description {
      margin-bottom: 4rem;
    }
  }
}

@media screen and (max-width: 414px) {
  .LoadingActivity {
    position: relative;
    width: calc(
      100vw - var(--paddingAppSizeResponsive) - var(--paddingAppSizeResponsive)
    );
    grid-template-columns: 1fr;

    .photo {
      width: calc(
        100vw - var(--paddingAppSizeResponsive) -
          var(--paddingAppSizeResponsive)
      );
      height: calc(
        100vw - var(--paddingAppSizeResponsive) -
          var(--paddingAppSizeResponsive)
      );
    }

    .content {
      .title {
        width: 50%;
      }

      .bloc {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;

        .button {
          margin-bottom: 0rem;
        }
      }
    }
  }
}
