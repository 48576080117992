.LessonCard {
  display: flex;
  flex-direction: column;
  background-color: var(--creamDarkest);
  color: var(--pharlap);
  border-radius: 0.3rem;
  box-shadow: var(--shadowBase);
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
  align-items: center;

  .subject {
    color: var(--chocolate);
  }

  .name {
    font-size: 1rem;
  }

  &.activity {
    background-color: var(--cold-turkey);
    border: 0.2rem dashed var(--chocolate);

    .subject {
      color: var(--chocolate);
    }

    -webkit-animation: pulsate 0.5s ease-in-out infinite both;
    animation: pulsate 0.5s ease-in-out infinite both;
  }
}
