a.NavigationLink,
.NavigationLink {
  color: var(--chocolate);
  padding-bottom: 1rem;

  svg {
    padding-right: 1rem;
  }

  &:hover {
    color: var(--pharlap) !important;
  }

  &.active {
    color: var(--pharlap) !important;
    border-bottom: 0.2rem solid var(--pharlap);
  }
}
