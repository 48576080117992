.Footer {
  padding-left: var(--paddingAppSize);
  padding-right: var(--paddingAppSize);
  background-color: var(--chocolate);
  color: var(--cream);
  font-size: 1.4rem;
  z-index: 0;

  a {
    color: var(--cream);

    &:hover {
      color: var(--pharlap);
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h3 {
    color: var(--pharlap);
    margin-bottom: 2rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 5rem;
    padding-bottom: 6rem;
    gap: 4rem;
  }
}

@media screen and (max-width: 568px) {
  .Footer {
    padding-left: var(--paddingAppSizeResponsive);
    padding-right: var(--paddingAppSizeResponsive);
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    .footer-menu {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 414px) {
  .Footer {
    padding-left: var(--paddingAppSizeResponsive);
    padding-right: var(--paddingAppSizeResponsive);

    .footer-menu {
      grid-template-columns: 1fr;
    }
  }
}
