.MainNavigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    gap: 3.2rem;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    padding-left: 0rem;
  }
}
