.HeaderMobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .HeaderMobile {
    display: grid;
    position: relative;
    padding: 1rem;
    padding-left: calc(var(--paddingAppSizeResponsive) + 1rem);
    padding-right: calc(var(--paddingAppSizeResponsive) + 1rem);
    grid-template-columns: 1fr auto 1fr;
    z-index: 2;

    .menu-complet {
      position: fixed;
      background-color: var(--cream);
      z-index: 999;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding: 4rem;
      padding-top: 8rem;
      display: none;

      button {
        position: fixed;
        top: 0rem;
        right: 0rem;
        font-size: 3.2rem;
        padding-top: 2.4rem;
        color: var(--chocolate);

        &:hover {
          color: var(--pharlap);
        }
      }

      ul {
        margin-bottom: 12rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3.2rem;
        list-style: none;
        padding-left: 0rem;

        li {
          a {
            font-size: 3.2rem;
            color: var(--chocolate);

            &:hover {
              color: var(--pharlap);
            }

            &:active {
              color: var(--pharlap);
            }

            &.button-link {
              padding-left: 0rem;
            }
          }
        }
      }

      .header-mobile-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 3.2rem;
        margin-top: 3.2rem;

        .BackToPortal {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .SelectLanguage {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }

      &.visible {
        display: flex;
        flex-direction: column;
      }
    }

    .burger {
      background: none;
      border-radius: 0rem;
      border: 0rem solid transparent;
      padding: 1.2rem;
      padding-left: 0rem;

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        width: 2.8rem;
        height: 2rem;

        &:hover span {
          border-top: 0.25rem solid var(--pharlap);
        }

        span {
          border-top: 0.25rem solid var(--chocolate);
          border-radius: 1rem;
          height: 0.1rem;
          width: 100%;
        }

        span:nth-child(even) {
          width: 1.8rem;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 3.2rem;

      a {
        color: var(--chocolate);
        padding: 0rem;

        svg {
          padding-right: 0rem;
          font-size: 2.8rem;
        }

        &.CallAESButton svg {
          margin-right: 0rem !important;
        }

        border-width: 0rem;

        span {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .HeaderMobile {
    .menu-complet {
      button {
        font-size: 2.4rem;
      }

      ul {
        li {
          a {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
}
