.Breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  font-family: "HelveticaNeueLTStd-Bold";

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    list-style: none;
    padding-left: 0rem;

    li {
      text-transform: uppercase;

      a {
        color: var(--chocolate);

        &:hover {
          color: var(--pharlap);
        }
      }

      &:last-child a {
        color: var(--pharlap) !important;
      }
    }
  }
}
