.LogoAES {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "VarelaRound-Regular", Courier, monospace;
  font-size: 3.2rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  a {
    color: var(--gold);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &:hover {
      color: var(--gold);
    }
  }
}

@media screen and (max-width: 1024px) {
  .LogoAES {
    img {
      width: 40px;
    }

    strong {
      display: none;
    }
  }
}
