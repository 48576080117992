@mixin boule {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  top: 2rem;
  left: 2.5rem;
  border: 0.2rem solid var(--white);
}

@mixin corner {
  width: 10rem;
  height: 10rem;
  top: -6rem;
  left: -6rem;
  transform: rotate(45deg);
  border: 0.4rem solid var(--cream);
}

@mixin barre {
  width: 1rem;
  height: 100%;
}
