.Cart {
  .table-area {
    margin-bottom: 2.4rem;
  }

  .button-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .Cart {
    max-width: calc(100vw - var(--paddingAppSize) - var(--paddingAppSize));
    overflow: scroll;

    .table-area {
      max-width: 100%;
      overflow: scroll;

      table {
        min-width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Cart {
    max-width: calc(
      100vw - var(--paddingAppSizeResponsive) - var(--paddingAppSizeResponsive)
    );
    overflow: scroll;

    .table-area {
      max-width: 100%;
      overflow: scroll;

      table {
        min-width: 1000px;
      }
    }
  }
}
