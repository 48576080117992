.BackToPortal {
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    padding-bottom: 0rem !important;
    text-transform: uppercase;

    svg {
      padding-right: 0rem !important;
      padding-bottom: 0.3rem;
    }
  }
}
