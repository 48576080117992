.Alert {
  background-color: var(--backgroundNone);
  padding: 4rem 4rem;
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: auto 1fr;

  &.danger {
    background-color: var(--backgroundDanger);
    color: var(--danger);
  }

  &.info {
    background-color: var(--backgroundInfo);
    color: var(--info);
  }

  &.warning {
    background-color: var(--backgroundWarning);
    color: var(--warning);
  }

  &.success {
    background-color: var(--backgroundSuccess);
    color: var(--success);
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.2;
    font-size: 10rem;
    padding-right: 4rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & *:last-child {
      padding-bottom: 0rem;
    }
  }
}

@media screen and (max-width: 414px) {
  .Alert {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .icon {
      font-size: 6rem;
      padding-bottom: 2rem;
    }
  }
}
