@import "../mixins/gradientLoading";

.LoadingTimetable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 2rem;
  gap: 1rem;

  div {
    height: 10rem;
    @include gradientLoading;
  }
}
