.Favorite {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  border-radius: 0rem;
  font-size: 2.4rem;
  border-width: 0rem;
  color: var(--white);

  svg {
    margin-right: 0rem;
  }

  &.is-favorite {
    color: var(--blood);
  }

  &:hover {
    color: var(--blood);
    background: none;
  }
}
