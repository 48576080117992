@import "../mixins/gradientLoading";

.LoadingOrders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  div:nth-child(odd) {
    background-color: var(--chocolate);
    height: 5rem;
    width: 30%;
    @include gradientLoading;
  }

  div:nth-child(even) {
    background-color: var(--chocolate);
    height: 20rem;
    margin-bottom: 2.4rem;
    width: 100%;
    @include gradientLoading;
  }
}
