.toggle-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;

  & > span {
    margin-top: 0.3rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 5rem;
    height: 2.7rem;

    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    & input:checked + .slider {
      background-color: var(--cream);
    }

    & input:checked + .slider:before {
      -webkit-transform: translateX(2.1rem);
      -ms-transform: translateX(2.1rem);
      transform: translateX(2.1rem);
    }

    & .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--cream);
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 1.9rem;
        width: 1.9rem;
        left: 0.2rem;
        bottom: 0.1rem;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      &.round {
        border-radius: 3.4rem;
        border: 0.3rem solid var(--chocolate);
      }

      &.round:before {
        border-radius: 50%;
        background-color: var(--chocolate);
      }
    }
  }
}
