.ErrorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: var(--chocolate);
  color: var(--white);

  &.bg-danger {
    background-color: var(--danger);

    section {
      h1 {
        color: var(--dangerDarkest);
      }

      a {
        color: var(--danger);
      }
    }
  }

  section {
    padding: var(--paddingAppSize);
    max-width: var(--maxAppWidth);
    margin-left: auto;
    margin-right: auto;

    img {
      margin-bottom: 1.6rem;
    }

    h1 {
      color: var(--pharlap);
    }

    p {
      font-size: 2.4rem;
      padding-bottom: 4rem;
    }
  }
}

@media screen and (max-width: 414px) {
  .ErrorPage {
    section {
      padding: var(--paddingAppSizeResponsive);
    }
  }
}
