.OpenBanner {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: var(--contessa);
  padding: var(--paddingAppSizeResponsive);
  padding-top: 1.4rem;
  padding-left: var(--paddingAppSize);
  padding-right: var(--paddingAppSize);
  color: var(--white);
  text-align: center;
  gap: 1rem;
}

@media screen and (max-width: 1024px) {
  .OpenBanner {
    flex-direction: column;
    padding-left: var(--paddingAppSizeResponsive);
    padding-right: var(--paddingAppSizeResponsive);
  }
}
