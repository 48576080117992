.App {
  display: grid;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.container {
  max-width: var(--maxAppWidth);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--paddingAppSize);
  padding-right: var(--paddingAppSize);
  padding-top: 6rem;
  padding-bottom: 10rem;
}

.container-2 {
  max-width: var(--maxAppWidth);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--paddingAppSize);
  padding-right: var(--paddingAppSize);
  padding-top: 3rem;
  padding-bottom: 10rem;
}

@media screen and (max-width: 568px) {
  .container {
    padding-left: var(--paddingAppSizeResponsive);
    padding-right: var(--paddingAppSizeResponsive);
  }

  .container-2 {
    padding-left: var(--paddingAppSizeResponsive);
    padding-right: var(--paddingAppSizeResponsive);
  }
}
