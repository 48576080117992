@-webkit-keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
