/* Customize the label (the container) */
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 3rem;
  cursor: pointer;

  span {
    padding-top: 0.4rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.4rem;
    background-color: #eee;
  }

  &:hover input ~ .checkmark {
    background-color: var(--creamDarker);
  }

  & input:checked ~ .checkmark {
    background-color: var(--chocolate);
  }

  &.blue input:checked ~ .checkmark {
    background-color: var(--cerulean);
  }

  &.yellow input:checked ~ .checkmark {
    background-color: var(--orange);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 0.9rem;
    top: 0.5rem;
    width: 0.5rem;
    height: 1rem;
    border: solid var(--creamDarker);
    border-width: 0 0.3rem 0.3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
