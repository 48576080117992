.Copyright {
  position: relative;
  font-size: 1.2rem;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.1);
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;

  svg {
    color: var(--blood);
    animation: heartbeat 1.5s ease-in-out infinite both;
  }
}

@media screen and (max-width: 414px) {
  .Copyright {
    padding-bottom: 3.2rem;
  }
}
