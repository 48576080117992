.ActivityDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;

  .top-part {
    display: grid;
    width: 100%;
    grid-template-columns: 40rem 1fr;
    gap: 4rem;

    .isComplete {
      background-color: var(--warning);
      color: var(--white);
      margin-bottom: 2rem;
      padding: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      span {
        margin-top: 0.5rem;
      }
    }

    figure {
      width: 40rem;
      height: 40rem;
      background-color: var(--chocolate);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        max-width: none;
        width: auto;
        height: 100%;
      }
    }

    .information {
      .entete {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 2rem;
      }

      .price-area {
        margin-bottom: 2.4rem;
        font-size: 2rem;
      }

      .line-info {
        padding-bottom: 1.2rem;

        .col-1x {
          display: grid;
          grid-template-columns: 1fr;
        }

        .col-2x {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 3rem;
        }

        .col-3x {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 3rem;
        }

        .registrations {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 1rem;
          flex-wrap: wrap;
        }
      }
    }
  }

  .bottom-part {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 2.4rem;

    .bloc-additional-info {
      margin-top: 2.4rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;

      .bloc-info {
        background-color: var(--creamDarker);
        padding: 2rem;

        dd {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ActivityDetails {
    .top-part {
      grid-template-columns: 30rem 1fr;

      figure {
        width: 30rem;
        height: 30rem;
      }
    }
  }
}

@media screen and (max-width: 834px) {
  .ActivityDetails {
    .top-part {
      grid-template-columns: 1fr;
      figure {
        width: calc(100vw - var(--paddingAppSize) - var(--paddingAppSize));
        height: calc(100vw - var(--paddingAppSize) - var(--paddingAppSize));
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .ActivityDetails {
    .top-part {
      grid-template-columns: 1fr;
      figure {
        width: calc(
          100vw - var(--paddingAppSizeResponsive) -
            var(--paddingAppSizeResponsive)
        );
        height: calc(
          100vw - var(--paddingAppSizeResponsive) -
            var(--paddingAppSizeResponsive)
        );
      }

      .information {
        .entete {
          display: flex;
          flex-direction: column-reverse;
        }

        .line-info {
          .col-3x {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }

    .bottom-part {
      .bloc-additional-info {
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  }
}
