.ShoppingBagMenu {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .ShoppingBagMenu {
    span {
      display: none;
    }

    svg {
      padding-right: 0rem;
      font-size: 2.8rem;
    }

    sup {
      top: -2rem;
    }
  }
}
