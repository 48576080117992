.SearchItem {
  padding-bottom: 1rem !important;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    width: 100%;

    .picture {
      width: 10rem;
      height: 8.2rem;
      background-color: var(--chocolate);
      overflow: hidden;
      background-size: cover;
      background-position: center;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;

      div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        strong {
          color: var(--chocolate);
          margin-right: 1rem;
          font-size: 1.6rem;
          margin-bottom: 0rem;
        }

        em {
          display: inline-block;
          width: 1.6rem;
          height: 1.6rem;
          background-color: var(--cream);
          border-radius: 50%;

          &.orange {
            background-color: var(--orange);
          }

          &.blue {
            background-color: var(--cerulean);
          }
        }
      }

      span {
        color: var(--pharlap);
        font-size: 1.2rem;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--cream);
  }
}
