.SecondNavigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 3.2rem;
    list-style: none;
    padding-left: 0rem;
  }
}
