@import "../mixins/gradientLoading";

.LoadingActivities {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  .blocActivities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;

    div {
      background-color: var(--chocolate);
      height: 25rem;
      @include gradientLoading;
    }
  }
}

@media screen and (max-width: 414px) {
  .LoadingActivities {
    .blocActivities {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
}
